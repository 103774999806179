import { redirect } from 'react-router-dom';

export function getAuthToken() {
    const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let token;

token = urlParams.get('jwt_token');
//console.log(token);
if(token){
    localStorage.setItem('token',token);
}
    else{
        token = localStorage.getItem('token');
    }

    return token;
  }




  export async function tokenValidator(token) {
    const { REACT_APP_API_URL } = process.env;

    // Helper function to validate token
    async function validateToken(token) {
        if(!token) return;
        const response = await fetch(`${REACT_APP_API_URL}myuser/me/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });


        if ( !response.ok) {
          return null;
        }

        return response; // Return the fetch response for further processing

    }

    // Helper function to refresh token
    async function refreshToken(refreshToken) {
      try {
        const response = await fetch(`${REACT_APP_API_URL}myuser/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });
        if (!response.ok) {
         return null;
        }
        const resData = await response.json();
        return resData.access; // Return the new access token
      } catch (error) {
        // Handle specific token refresh errors here if needed
        //console.log(error); // Re-throw to be handled by the caller
      }
    }

    try {
      let response = await validateToken(token);
      //console.log(response);
      if (!response || !response.ok) {
        const refresh = localStorage.getItem('refresh');
        //console.log(refresh)
        if (!refresh) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('userData');
          return false;
        }
        const newToken = await refreshToken(refresh);
        //console.log(newToken);
        localStorage.setItem('token', newToken);
        response = await validateToken(newToken); // Retry validation with the new token
        //console.log(response)
        if (!response.ok) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('userData');
          return false;
        }
      }

      if ([401, 422].includes(response.status)) {
        // Specific error handling for 401 Unauthorized or 422 Unprocessable Entity
        return false;
      }

      const userData = await response.json();
      //console.log(userData);
      localStorage.setItem('userData', JSON.stringify(userData));
      return true; // Token is valid and user data is stored

    } catch (error) {
      console.error(error.message);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('userData');
      return false; // Return false or handle the error as needed
    }
  }



  export async function tokenLoader() {
    //console.log("entra aqui")
    const token = getAuthToken();
    //console.log(token);
    const validToken = await tokenValidator(token);
    //console.log(validToken)
    const language = localStorage.getItem('language');
    if(!language)
    {
        localStorage.setItem('language','pt');

    }
    if (!validToken){
      //console.log("devia remover")
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
        return redirect('/login');
    }

    //console.log(token);

    return token;
  }



  function getCookie(name) {
    let cookieValue = null;
    console.log('Document cookies:', document.cookie); // Log the cookies

    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        console.log('Checking cookie:', cookie); // Log each cookie being checked
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }

    console.log('Retrieved cookie value:', cookieValue); // Log the retrieved cookie value
    return cookieValue;
  }
  export function getCSRFToken () {
    return getCookie('csrftoken');
  }

  export function getAccessToken () {
    return getCookie('mid_access_token');
  }
