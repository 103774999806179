import { json } from "react-router-dom";
import { getCSRFToken } from "./auth";
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient();

export function extractFields(data) {
  let names = [];

  data.forEach(item => {
    // Handling direct fields without sections
    if (item.type !== 'section' && item.name) {
      names.push({name:item.name,label:item.label,object:item.object});
    }
    // Handling fields nested within 'section'
    else if (item.type === 'section' && item.fields) {
      item.fields.forEach(field => {
        if (field.name) {
          names.push({name:field.name,label:field.label,object:field.object});
        }
      });
    }
  });

  //console.log(names);
  return names;
}




export const fetchMenuItems = async (menuType) => {
  const token = getCSRFToken();
  const language = localStorage.getItem("language") || "pt"; // Replace 'defaultLanguage' with your default language if not set in localStorage
    try {

      const headers = token ? {   'X-CSRFToken': token } : {};
      const response = await fetch(`${process.env.REACT_APP_API_URL}webapp/item/menu/${menuType}?language=${language}`, {
        headers: headers,
         credentials:"include"
      });
      const data = await response.json();

      // Sort the items by 'order' if available
      if (data.items) {
        data.items.sort((a, b) => a.order - b.order);
      }

      return data;
    } catch (error) {
      console.error(`Error fetching ${menuType} menu items:`, error);
      return null; // Return null or appropriate error handling
    }
  };

  export const fetchForm = async (formAlias,form='form') => {

    const token = getCSRFToken();
    const language = localStorage.getItem("language") || "pt";
    const user = localStorage.getItem("user") || "";
    let label;
    if (formAlias === 'my-profile') {
      if (user.startsWith('CLI')) {
        label = `${formAlias}-client`;
      } else if (user.startsWith('AG')) {
        label = `${formAlias}-agent`;
      }
    }
    try {
      const headers = token ? {   'X-CSRFToken': token } : {};
      const response = await fetch(`${process.env.REACT_APP_API_URL}webapp/item/${form}/${formAlias}?label=${label}&language=${language}`, {
        headers: headers,
  credentials:"include"
      });

      //console.log(response)
      //console.log(token)
      if (!response.ok) {
        //console.error(`Error loading data from ${loaderData.url}. Status: ${response.status}`);
        //console.log("throw json aqui")

        throw json({ message: 'Could not authenticate user.' }, { status: response.status });
      }

      const data = await response.json();
console.log(data);
      // Sort the items by 'order' if available


      return data;
    } catch (error) {
      //console.log(error)
      throw json({ message: 'Could not authenticate user.' }, { status: error.status });

    }
  };

  export const fetchItem = async (obj,alias) => {

    const token = getCSRFToken();
    console.log(token);
    try {
      const headers =  {   'X-CSRFToken': token};
      const response = await fetch(`${process.env.REACT_APP_API_URL}webapp/item/${obj}/${alias}`, {
        headers: headers,
        credentials:"include"

      });

      //console.log(response)
      //console.log(token)
      if (!response.ok) {
        //console.error(`Error loading data from ${loaderData.url}. Status: ${response.status}`);
        //console.log("throw json aqui")

        throw json({ message: 'Could not authenticate user.' }, { status: response.status });
      }

      const data = await response.json();

      // Sort the items by 'order' if available


      return data;
    } catch (error) {
      //console.log(error)
      throw json({ message: 'Could not authenticate user.' }, { status: error.status });

    }
  };

  export const fetchNotifications = async () => {
    try {
      const token = getCSRFToken();
      const headers = token ? {   'X-CSRFToken': token, } : {};
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/core/message/get`, {
        headers: headers,
         credentials:"include"
      });
      const data = await response.json();
      //console.log(data)
      if (!response.ok) {
        // Handle HTTP errors
        throw json({ message: response.statusText }, { status: response.status });

      }

      return data;
    } catch (error) {
      throw json({ message: error.text }, { status: error.status });

    }
  };

  export const deleteNotifications = async (id) => {
    //console.log(id)
    const token = getCSRFToken();
    try {
      const headers = {
          'Content-Type': 'application/json',
          'X-CSRFToken': token,
      };
      const body = JSON.stringify({ messages: [id.id] });

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/core/message/delete`, {
          method: 'POST',
          headers: headers,
          body: body,
           credentials:"include"
      });

      if (!response.ok) {
          throw new Error('Failed to delete notification');
      }

      return await response.json(); // Assuming the API responds with updated notification object
  } catch (error) {
      console.error("Error deleting notification:", error);
      throw error;
  }
};

  export const markNotificationAsRead = async (notificationId) => {

    const token = getCSRFToken();
    try {
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': token,
        };
        const body = JSON.stringify({ is_read: true });

        const response = await fetch(`${process.env.REACT_APP_API_URL}api/core/message/modify/${notificationId}`, {
            method: 'PATCH',
            headers: headers,
            body: body,
             credentials:"include"
        });

        if (!response.ok) {
            throw new Error('Failed to mark notification as read');
        }

        return await response.json(); // Assuming the API responds with updated notification object
    } catch (error) {
        console.error("Error marking notification as read:", error);
        throw error;
    }
};

  export const fetchCompanyLogo = async (company) => {

    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}webapp/brand/${company}`, {

      });

      if (!response.ok) {
        // Handle HTTP errors
        //console.log(`Error loading company data. Status: ${response.status}`);
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw json({ message: error.text }, { status: error.status });

    }
  };




  export const fetchDataObjectDetail = async (dataForm,context) => {

  console.log(dataForm);

  const {model_name, app_name, model_key_id,model_id, fields} = dataForm;
  const url = `webapp/updater`;
  //console.log(url);

  if(!dataForm) return;
  const token = getCSRFToken();
  const language = localStorage.getItem('language');
  const user = localStorage.getItem('user');
  //console.log(user);
  try {
    const headers = token ? {   'X-CSRFToken': token, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
    const body = {
      "action": context,
      "app_name": app_name,     // Using variable app_name
      "model_name": model_name, // Using variable model_name
      "model_key_id": model_key_id, // Using variable model_key_id
      "model_id": model_id,     // Using variable model_id
      "fields": fields ,
      "language":language ,      // Using variable fields
      "user":user,

    };
      //console.log(body)
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
  credentials:"include"

    });

    console.log(response);

    if (!response.ok) {
      //console.log(response)
      throw json({ message: response.statusText }, { status: response.status });
    }

    const responseData = await response.json();
    //console.log(responseData)
    return responseData;
  } catch (error) {
    //console.log(error)
    console.log("para qui",error);
    throw json({ message: error.text }, { status: error.status });
  }

  };

  export const fetchDataObject = async ({ params, request }, alias, context) => {
    //console.log(params)
    const loadDataTable = async () => {

      const token = localStorage.getItem("token");

      const data = await fetchItem("datatable",alias,token);

      return data;
    }
    const dataTable = await loadDataTable();






    const {label,model_name, app_name, model_key_id, fields,buttons} = dataTable.items;

    const meta = dataTable?.meta || "";
    const breadcrumb = dataTable?.breadcrumb.data || "";


    const url = `webapp/updater`;
    //console.log(url);

    const token = getCSRFToken();
    console.log(token)
    const language = localStorage.getItem('language');
    try {
      const headers = { 'Content-Type': 'application/json',  'X-CSRFToken': token, };
      const body = {
        "action": context,
        "label":label,
        "app_name": app_name,     // Using variable app_name
        "model_name": model_name, // Using variable model_name
        "model_key_id": model_key_id, // Using variable model_key_id
        "language":language,
        "fields": fields ,         // Using variable fields
        "buttons":buttons,
        "meta":meta,
        "breadcrumb":breadcrumb,

      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
credentials:"include",

      });

      if (!response.ok) {
        //console.log(response)
        throw json({ message: response.statusText }, { status: response.status });
      }

      const responseData = await response.json();
      //console.log(responseData)
      return responseData;
    } catch (error) {
      //console.log(error)
      throw json({ message: error.text }, { status: error.status });
    }
  };

  export const fetchLeads = async ({ request, params }) => {
    const id = params.id;

    const url = id ? `webapp/leads/${id}` : "webapp/leads";

    const token = localStorage.getItem("token");
    try {
      const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        headers: headers,
         credentials:"include"
      });

      if (!response.ok) {
        //console.log(response)
        throw json({ message: response.statusText }, { status: response.status });


      }

      const data = await response.json();
      return data;
    } catch (error) {
      //console.log(error)
      throw json({ message: error.text }, { status: error.status });

    }
  };


  export const fetchSelectItems = async (objDetails,field) => {

    //console.log(objDetails);
    const {model_name, app_name,field_key,filter,search} = objDetails;
    const url = `webapp/updater`;
    //console.log(url);

    const token = getCSRFToken();
    const language = localStorage.getItem('language');

    try {
      const headers = token ? {  'X-CSRFToken': token, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
      const body = {
        "action": "select_item",
        "app_name": app_name,     // Using variable app_name
        "model_name": model_name, // Using variable model_name
        "field_key":field_key,
        "filter":filter,
        "search":search,
        "language":language,
        "fields": field ,         // Using variable fields

      };
        //console.log(body)
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
credentials:"include"
      });

      if (!response.ok) {
        //console.log(response)
        throw json({ message: response.statusText }, { status: response.status });
      }

      const responseData = await response.json();
      //console.log(responseData)
      return responseData.data;
    } catch (error) {
      //console.log(error)
      throw json({ message: error.text }, { status: error.status });
    }

  }

  export const fetchTranslationFromLabel = async (label, language, token) => {
    try {
        // Construct the URL with query parameters
        const url = `${process.env.REACT_APP_API_URL}translations/get-translation-from-label/?label=${encodeURIComponent(label)}&language=${encodeURIComponent(language)}`;

        const token = localStorage.getItem("token");
        //console.log(token);

        // Prepare the request headers, including the Authorization header if a token is provided
        const headers = token ? { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };

        // Make the GET request with headers
        const response = await fetch(url, { headers });

        // Check if the response is OK (status in the range 200-299)
        if (!response.ok) {
          // If the response is not OK, log the error and return the label within backticks as the fallback translation
          console.error(`Error fetching translation for label "${label}" in language "${language}". Status: ${response.status}`);
          return `\`${label}\``; // Return label within backticks
      }

      // Parse the JSON response
      const data = await response.json();

      // Return the 'text' field from the fetched translation data, or the label within backticks if the translation is missing
      return data.text || `\`${label}\``; // Ensure data.text is correctly keyed as per your API response structure
  } catch (error) {
      // If an error occurs during the fetch operation, log the error and return the label within backticks as the fallback translation
      console.error(`Error fetching translation for label "${label}" in language "${language}":`, error);
      return `\`${label}\``; // Return label within backticks as fallback translation
  }
};

export const fetchCompanyDetails = async (company) => {

  try {

    const response = await fetch(`${process.env.REACT_APP_API_URL}brand/companies/by_alias/?alias=${company}`, {

    });

    if (!response.ok) {
      // Handle HTTP errors
      //console.log(`Error loading company data. Status: ${response.status}`);
      return null;
    }

    const data = await response.json();
    //console.log(data)
    return data;
  } catch (error) {
    throw json({ message: error.text }, { status: error.status });

  }
};


// WebSocket connection setup for notifications





export const fetchChatbotResponse = async (message) => {
  const url = `chatbot/call-chat/`; // Chatbot API endpoint
  const token = getCSRFToken(); // Assuming you store your token in localStorage

  try {
    const headers = token ? {'X-CSRFToken': token, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
    const body = {
      "query": message, // Your message to the chatbot
      "chatbot_name":"dona"
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
       credentials:"include"
    });

    if (!response.ok) {
      // If the server response was not ok, throw an error
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Failed to fetch chatbot response');
    }

    const responseData = await response.json();
    return responseData; // Assuming the API response structure you want to work with
  } catch (error) {
    console.error('Fetch Chatbot Response Error:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
};


export const updateProfile = async (pk,type) => {

  //console.log(objDetails);

  const url = `webapp/updater`;
  //console.log(url);

  const token = getCSRFToken();
;

  try {
    const headers = token ? {  'X-CSRFToken': token, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
    const body = {
      "action":"update_profile",
      "profile": pk,
      "profile_type":type

    };
      //console.log(body)
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
      credentials:"include"
    });

    if (!response.ok) {
      //console.log(response)
      throw json({ message: response.statusText }, { status: response.status });
    }

    const responseData = await response.json();
    //console.log(responseData)
    return responseData.data;
  } catch (error) {
    //console.log(error)
    throw json({ message: error.text }, { status: error.status });
  }

}



export const fetchHolidayEvents = async () => {
  const url = `api/holliday/between`; // Replace with your actual endpoint
  const currentYear = new Date().getFullYear();
  const startDate = `${currentYear}-01-01`;
  const endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().split('T')[0];

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        period: { start: startDate, end: endDate },
        country: ['pt'],
      }),
    });

    if (!response.ok) {
      throw new Error(`Error loading holiday data. Status: ${response.status}`);
    }

    const data = await response.json();
    return data.hollidays.map(holiday => ({
      title: holiday.name,
      start: holiday.date,
      color: '#10B981', // Set the color to green
      allDay: true,
    }));
  } catch (error) {
    console.error('Fetch Holiday Events Error:', error);
    throw error;
  }
};