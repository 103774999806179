import React, { useContext, useState , useCallback,useMemo,useEffect} from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { LinearProgress } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import {Typography} from '@mui/material';
import PlatFormLogo from '../../UI/PlatFormLogo';
import {useMediaQuery,useTheme} from '@mui/material';
import { Link } from 'react-router-dom';
import AuthContext from '../../../store/context/auth-context__';
import { useSnackBar } from '../../../store/context/snackbar-context';
import Modal from '../../UI/Modal';

import useTranslations from '../../../hooks/use-translations';
import { useTranslationsContext } from '../../../store/context/translations-context';
import Notifications from './Notifications';
import ProfileOptions from './ProfileOptions';
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerwidth }) => ({
  position: 'fixed',
  top: 0,
  zIndex: !open ? theme.zIndex.drawer + 1 : theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
  }),
  ...(open && {
    marginLeft: `${drawerwidth}px`,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

function TopNav(props) {
  const authCtx = useContext(AuthContext);

  const {  requestTranslation } = useTranslationsContext();
  const language = localStorage.getItem("language") || "pt";
  const translate = useTranslations(language);
  const [translatedText, setTranslatedText] = useState({

    completeProfile:'',
  });
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOpenUserMenu = useCallback((event) => setAnchorElUser(event.currentTarget), []);
  const handleCloseUserMenu = useCallback(() => setAnchorElUser(null), []);
  const handleModalOpen = useCallback(() => setOpenModal(true), []);
  const handleModalClose = useCallback(() => setOpenModal(false), []);
  const { notify } = useSnackBar();
  const handleLogout = useCallback(async () => {
    const transLogoutMessage = await translate('logout-success');

    authCtx.logoutHandler();
    setOpenModal(false);
    setAnchorElUser(null);
    notify(transLogoutMessage,'info');

     // eslint-disable-next-line
  }, [authCtx, requestTranslation, notify]);

  // Memoize props for Modal to prevent re-renders caused by new object references
  const modalProps = useMemo(() => ({
    title: 'exit',
    message: 'confirm-exit',
    open: openModal,
    onClose: handleModalClose,
    action: handleLogout,
  }), [openModal, handleModalClose, handleLogout]);

  useEffect(() => {
    const translateLabels = async () => {
      const completeProfile = await translate('complete-your-profile');

      setTranslatedText({ completeProfile });
    };

    translateLabels();
  }, [translate]); // Re-run if the language or trans


  return (
    <React.Fragment>
      <AppBar
        style={{ backgroundColor: props.backgroundColor }}
        position="fixed"
        open={props.open}
        drawerwidth={props.drawerWidth}
        elevation={0}
        className='h-auto top-0'
      >
        <Toolbar className='flex justify-between'>
          <Box className='flex gap-2 sm:gap-6 '>
            <IconButton
              style={{ color: props.color }}
              edge="start"
              aria-label="open drawer"
              onClick={props.sideBarHandler}
              sx={{  ...(props.open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box className="flex gap-8">
            <Link className='flex items-center' to=".">
              <PlatFormLogo type={isMobile? "entrance" : "small"} className={`max-h-8 ${props.open &&  'hidden sm:block'} `} />
            </Link>
            <Link to={"profile/my-profile"}>
            <Box className="flex flex-col items-start gap-2">

              <Typography className=' text-primary-main' variant="subtitle2">{translatedText.completeProfile}</Typography>
              <LinearProgress variant="determinate" value={60} style={{ width: '7rem' }} />

            </Box>
            </Link>
            </Box>
          </Box>
          <Box className="flex items-center gap-2 sm:gap-6">

              <Notifications />

            <Tooltip title="Opções de Perfil">
              <IconButton onClick={handleOpenUserMenu} size='medium'>
                <span className={`p4l-person-line w-fit`}></span>
              </IconButton>
            </Tooltip>

            <ProfileOptions
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              menuItems={props.topNavItems.items}
              profiles={props.topNavItems.profiles}
              onOpen={handleModalOpen}
            />

          </Box>
        </Toolbar>
      </AppBar>

      {openModal && <Modal {...modalProps} />}

    </React.Fragment>
  );
}

export default React.memo(TopNav);