import React from 'react';

const Tabs = ({ tabs, selectedTab, onTabChange, children }) => {
  return (
    <div>
      <div className="overflow-x-auto whitespace-nowrap text-center flex gap-0.5 bg-gray-100 text-2xl">
        {tabs.map(tab => (
          <button
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            className={`inline-block w-96 py-9 px-2  transition-all duration-200 ease-out ${
              selectedTab === tab.value
                ? 'bg-white text-black font-bold py-7'
                : 'align-bottom text-gray-400 hover:bg-white hover:text-black hover:font-bold hover:py-7 active:bg-white active:text-black active:py-7'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="w-full p-6 bg-white">
        {tabs.map(tab => (
          <div key={tab.value} className={`${selectedTab !== tab.value ? 'hidden' : ''}`}>
            {selectedTab === tab.value && children}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
