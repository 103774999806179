import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemText, IconButton, Collapse, List, useTheme } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Divider} from '@mui/material';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useLocation  } from 'react-router-dom';
function SideBarOption(props) {
  const [subOpen, setSubOpen] = useState(false);
  const hasSubitems = props.item.subitems && props.item.subitems.length > 0;
  const theme = useTheme();
  const location= useLocation(); // This should match the dynamic part of your route's path

// Define colors for parent and subitems
// eslint-disable-next-line
const isCurrentPath = location.pathname.includes(props.item.destination) || location.pathname==='/' && props.item.destination===null && props.item.subitems.length===0;
console.log(isCurrentPath)
console.log(location.pathname)
console.log(props.item)
console.log(props.item.destination)
console.log(props.item.subitems)
console.log(props.item.subitems.length)
const subitemColor = alpha(theme.palette.primary.dark, 0.1); // lighter shade for subitems
const currentItemColor = alpha(theme.palette.primary.main, 0.6);
  const handleItemClick = (e) => {
    if (hasSubitems) {
      e.preventDefault(); // Prevent default link behavior when there are subitems.

      // Toggle the expansion of subitems on click regardless of the sidebar state.
      setSubOpen(!subOpen);
    } else {
      // If there are no subitems, handle navigation or provided click events.
      if (props.onClick) {
        props.onClick();
      }
    }
  };
console.log(props.item)
  return (

    <>

      <Link className='w-full'  to={hasSubitems ? '#' : props.item.destination} onClick={handleItemClick} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Tooltip  title={props.item.name} placement="right">
        <ListItem sx={{ px: 0, py: 0 ,  }} className='w-full' style={isCurrentPath ? { backgroundColor: currentItemColor } : {}}>

          <ListItemButton sx={{ px: 3, py: 0 }} className='w-full'>

            <div className={`cursor-pointer py-4 w-full flex gap-8 justify-center items-center text-primary-main`}>

              <IconButton size='small'>
                <span className={`${props.item.icon ? props.item.icon : ''} w-6 text-primary-main h-full`}></span>
              </IconButton>

              <ListItemText
                primary={props.item.name}
                className={`${props.open ? 'block text-left opacity-100' : 'hidden opacity-0'}`}
                primaryTypographyProps={{ style: { fontWeight: hasSubitems ? 'bold' : 'normal' } }}
              />
               {hasSubitems && !props.open && (subOpen ? <ExpandLess sx={{ position: 'absolute', bottom: 0 }} /> : <ExpandMore sx={{ position: 'absolute', bottom: 0 }} />)}
              {hasSubitems && props.open && (subOpen ? <ExpandLess /> : <ExpandMore />)}
            </div>
          </ListItemButton>
        </ListItem>
        </Tooltip>
      </Link>
      {hasSubitems && (
        <Collapse in={subOpen} timeout="auto" unmountOnExit>
          <List className='w-full' component="div" disablePadding style={{ paddingLeft: theme.spacing(props.open? 4: 0) ,  backgroundColor: (!props.open && !isCurrentPath && subitemColor) || (isCurrentPath && currentItemColor) }} >
            {props.item.subitems.map((subitem) => (
               <React.Fragment>
              <SideBarOption
                key={subitem.alias}
                item={subitem}
                open={props.open}
                handleDrawerClose={props.handleDrawerClose}
                handleDrawerOpen={props.handleDrawerOpen}
                onClick={props.handleDrawerClose}
              />
                {subitem.divider && <Divider sx={{ borderColor: theme.palette.primary.main}}/>}
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default SideBarOption;
