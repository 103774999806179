import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Drawer, Divider, IconButton, List, useMediaQuery } from '@mui/material';
import P4lIcon from '../../UI/P4lIcon';
import SideBarOption from './SideNavOption';
import { Link } from 'react-router-dom';
import PlatFormLogo from '../../UI/PlatFormLogo';
import PerfectScrollbar from 'react-perfect-scrollbar';
function SideNav(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (props.open && !document.getElementById("sideNav").contains(event.target)) {
        props.handleDrawerClose();
      }
    };

    if (props.open) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => document.removeEventListener('mousedown', handleOutsideClick);
    /* eslint-disable */
  }, [props.open]);

  const processItemsToHierarchy = (items) => {
    let map = {}, roots = [];
    items.forEach(item => {
      map[item.alias] = {...item, subitems: []};
    });

    items.forEach(item => {
      if (item.parent && map[item.parent]) {
        map[item.parent].subitems.push(map[item.alias]);
      } else {
        roots.push(map[item.alias]);
      }
    });

    return roots;
  };

  const menuHierarchy = processItemsToHierarchy(props.sideBarItems.items || []);

  const openedMixin = {
    width: isMobile? "100%" : props.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: props.backgroundColor, // Use your theme's backgroundColor
  };

  const closedMixin = {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(0)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    backgroundColor: props.backgroundColor, // Use your theme's backgroundColor
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  return (
    <Drawer
      variant="permanent"
      open={props.open}
      id="sideNav"
      className='absolute sm:relative'
      sx={{
        ...(props.open ? { ...openedMixin, '& .MuiDrawer-paper': openedMixin, } : { ...closedMixin, '& .MuiDrawer-paper': closedMixin, }),
        ...(!props.open && isMobile && { display: 'none' }),
      }}
    >
      <DrawerHeader>
        {isMobile &&  <Link className='flex w-full items-center justify-center' to=".">
              <PlatFormLogo type={"small"} className={`max-h-8  `} />
            </Link>}

        <IconButton onClick={props.handleDrawerClose}>
          {theme.direction === 'rtl' ? <P4lIcon style={{ color: theme.palette.primary.main }} icon="p4l-right" size="medium" /> : <P4lIcon style={{ color: theme.palette.primary.main }} icon="p4l-left" size="medium" />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <PerfectScrollbar>
      <List>

        {menuHierarchy.map((item) => (
           <React.Fragment>

          <SideBarOption
            key={item.alias}
            item={item}
            open={props.open}
            handleDrawerClose={props.handleDrawerClose}
            handleDrawerOpen={props.handleDrawerOpen}
            onClick={props.handleDrawerClose}
          />
          {item.divider && <Divider sx={{ borderColor: theme.palette.primary.light}}/>}
          </React.Fragment>
        ))}

      </List>
      </PerfectScrollbar>
    </Drawer>
  );
}

export default SideNav;
