import React from 'react';
import { useLoaderData } from 'react-router-dom';
import MetaData from '../components/UI/MetaData';

import ContentPage from './ContentPage';
function Home() {
  const loaderData = useLoaderData();
  //console.log(loaderData);

  const meta = loaderData?.meta || "";



  return (
    <React.Fragment>
  <MetaData data={JSON.stringify(meta)}/>
    <ContentPage/>
    </React.Fragment>
  );
}

export default Home;



