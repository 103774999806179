import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AuthForm from '../components/Auth/AuthForm';

import MuiCard from '@mui/material/Card'
import { styled } from '@mui/material/styles'

import useTranslations from '../hooks/use-translations';

function NotificationPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { message,title,email } = location.state || {}; // Safely access message from state

    const language = localStorage.getItem("language") || "pt";
    const translate = useTranslations(language);


    const [translations, setTranslations] = useState({
        notifcationMessage: '',
        notificationTitle: '',
        backLogin: '',
      });


    const Card = styled(MuiCard)(({ theme }) => ({
        [theme.breakpoints.up('sm')]: { width: '28rem' }
      }))

      const LinkStyled = styled(Link)(({ theme }) => ({
        textDecoration: 'none',
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.main
      }))
    // Redirect if there is no message or the message is empty
    useEffect(() => {
        if (!message) {
            navigate('/', { replace: true });
        }
    }, [message, navigate]);




    useEffect(() => {
        const loadTranslations = async () => {
          const notifcationMessage = await translate(message);
          const notificationTitle = await translate(title);
          const backLogin = await translate('back-login');
          setTranslations({ notifcationMessage, notificationTitle,backLogin});
        };

        loadTranslations();
        /* eslint-disable */
      }, [translate]);

    return (
        <>
            {message && (
                <AuthForm social='disable'>
                <Box className="content-center">
                    <Card sx={{ zIndex: 1 }}>
                        <CardContent >
                        <Box sx={{ mb: 8 }}>
                        <Typography variant='h5' sx={{ mb: 2 }}>
                                {translations.notificationTitle} <span className='p4l-check'/>
                            </Typography>
                            <Typography variant='body1'  >
                                {translations.notifcationMessage} {email && <strong>{email}</strong>}
                            </Typography>
                            </Box>
                            <Typography className="flex gap-2 " variant="body2">
                        <Link to="/login">            <LinkStyled>
              {translations.backLogin}
            </LinkStyled></Link>
            </Typography>
                        </CardContent>

                    </Card>
                </Box>
                </AuthForm>
            )}
            </>

    );
}

export default NotificationPage;
